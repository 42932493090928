.pricing-wrapper {
  display: flex;
  gap: 3rem;
  justify-content: center; }

.pricing-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5rem 2rem;
  background-color: #fff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.07);
  border-radius: 0.75rem; }
  .pricing-box-price-number {
    font-size: 3rem;
    font-weight: 600; }
  .pricing-box-price-period {
    margin-left: .2rem;
    color: #6A7186; }
  .pricing-box-title {
    margin-bottom: 0;
    font-size: 1.5rem; }
  .pricing-box-description {
    font-size: 0.9375rem;
    color: #6A7186; }
  .pricing-box-separator {
    color: #BEC1CB; }
  .pricing-box-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 2rem;
    font-size: 0.9375rem; }
    .pricing-box-list li {
      display: flex;
      gap: .5rem;
      margin: 1rem 0; }
      .pricing-box-list li::before {
        content: "";
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.16' d='M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z' fill='%2300C854'/%3E%3Cpath d='M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z' stroke='%2300C854' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='m15 10-4 4-2-2' stroke='%2300C854' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center; }

@media screen and (max-width: 767px) {
  .pricing > .container {
    max-width: none; } }

@media screen and (max-width: 653px) {
  .pricing-wrapper {
    flex-direction: column; } }
