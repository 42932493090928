.blurb-icon {
  margin-bottom: .8rem; }

.blurb-item {
  text-align: center; }

.blurb-title {
  font-size: 0.9375rem; }

.blurb-desc {
  font-size: 0.875rem; }

@media screen and (max-width: 767px) {
  .blurb .container {
    max-width: none; } }
