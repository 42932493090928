.feature-inner {
  display: flex;
  align-items: center;
  gap: 2rem; }

.feature-image {
  flex: 0 0  auto; }

.feature-intro {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #00943E; }

.feature-title {
  font-size: 2.375rem;
  line-height: 1.3; }

.feature p {
  margin-bottom: 0;
  color: #535969; }

.feature-separator {
  margin: 1.5rem 0;
  width: 3.125rem;
  border-width: 0.1875rem;
  color: #BEC1CB; }

@media screen and (max-width: 991px) {
  .feature-image {
    flex: 0 0 50%; }
  .feature-title {
    font-size: 2rem; } }

@media screen and (max-width: 767px) {
  .feature > .container {
    max-width: none; } }

@media screen and (max-width: 575px) {
  .feature-inner {
    flex-direction: column; }
    .feature-inner .feature-image {
      order: 0; }
    .feature-inner .feature-text {
      order: 1; } }
