.hero {
  background-color: #E2F5EA; }
  .hero-inner {
    display: flex;
    align-items: center;
    gap: 2rem; }
  .hero-left {
    flex: 0 0 40%; }
  .hero-title {
    margin-bottom: .8rem;
    font-size: 2.375rem;
    font-weight: 700; }
  .hero-subtitle {
    margin-bottom: 1rem;
    font-size: 1.25rem; }
  .hero-downloads {
    display: flex;
    flex-direction: column;
    gap: 2rem; }
  .hero-cta-fomo {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    gap: .6rem;
    font-size: .82rem;
    line-height: 1.4; }
    .hero-cta-fomo p {
      margin: 0;
      text-align: left; }

@media screen and (max-width: 767px) {
  .hero-inner {
    flex-direction: column; }
  .hero-left {
    text-align: center; }
  .hero-downloads {
    justify-content: center; }
  .hero-cta-fomo {
    justify-content: center; }
  .hero-links {
    justify-content: center; } }

@media screen and (max-width: 430px) {
  .hero-title {
    font-size: 2rem; }
  .hero-downloads {
    flex-direction: column; }
  .hero-cta-fomo br {
    display: none; } }
