.features-grid {
  padding-bottom: 0; }
  .features-grid .row > div[class^="col-"] {
    margin-bottom: 2.5rem; }
  .features-grid-item {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .features-grid-item img {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      border: 1px solid rgba(218, 220, 226, 0.5); }
    .features-grid-item-title {
      font-size: 1.0625rem;
      font-weight: 700;
      padding-bottom: .5rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #DADCE2;
      line-height: 1.4;
      text-align: center; }
    .features-grid-item-description {
      margin: 0;
      font-size: 0.875rem; }
    .features-grid-item-text {
      flex: 1;
      padding: .8rem 1rem;
      background-color: #f4f4f6;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border: 1px solid rgba(218, 220, 226, 0.5); }

@media screen and (max-width: 767px) {
  .features-grid > .container {
    max-width: none; }
    .features-grid > .container .gx-5 {
      --bs-gutter-x: 1.5rem; } }

@media screen and (max-width: 575px) {
  .features-grid-item {
    text-align: center; } }
