.page-footer {
  padding: 2.5rem 0;
  background-color: #262930;
  color: #fff;
  font-size: 0.9375rem; }
  .page-footer-heading {
    margin-bottom: 1rem;
    color: #fff; }
  .page-footer-links {
    list-style-type: none;
    padding-left: 0; }
    .page-footer-links li {
      margin-right: 1.5rem;
      display: inline;
      color: #fff;
      text-decoration: none; }
      .page-footer-links li:hover {
        text-decoration: underline; }
  .page-footer-copyright {
    margin: 0;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.875rem; }

@media screen and (max-width: 991px) {
  .page-footer-heading {
    margin-top: 1.5rem; } }

@media screen and (max-width: 508px) {
  .page-footer-links > li {
    display: block; } }
