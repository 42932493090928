.star-rating img {
  display: inline;
  margin-right: .2rem; }

.testimonial {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .testimonial-group {
    display: inline-flex !important;
    justify-content: space-evenly; }
    .testimonial-group > .testimonial {
      flex: 0 0 auto;
      width: 30%; }
  .testimonial-rating {
    margin-bottom: 1rem; }
  .testimonial-content {
    margin-bottom: 1rem;
    font-size: .9rem;
    font-style: italic;
    color: #535969; }
  .testimonial-name {
    font-weight: 600; }
  .testimonial .slick-slide > div {
    height: 100%; }
  .testimonial-video {
    margin-bottom: 2.5rem;
    position: relative; }
    .testimonial-video .video-js, .testimonial-video .vjs-poster, .testimonial-video .vjs-tech {
      border-radius: .7rem; }
    .testimonial-video-overlay {
      position: absolute;
      top: .5rem;
      left: .5rem;
      padding: .25rem .5rem;
      border-radius: .25rem;
      background-color: rgba(0, 0, 0, 0.5); }
    .testimonial-video-title {
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
      color: #fff; }

@media screen and (max-width: 991px) {
  .testimonial-group > .testimonial {
    width: 45%; } }

@media screen and (max-width: 767px) {
  .testimonial > .container {
    max-width: none;
    padding-left: 2rem;
    padding-right: 2rem; } }

@media screen and (max-width: 669px) {
  .testimonial-group > .testimonial {
    width: 95%; } }
